<template>
  <b-form-group
    :label="$t('form.attribute_value_id.label')"
    :label-for="'attributes.' + index + '.attribute_value_id'"
    :state="error ? false : null"
  >

    <b-input-group class="pav-field">
      <template #prepend>
        <b-button
          v-if="$ability.can('store', 'attributes')"
          variant="secondary"
          class="prepend"
          @click="openEditor"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-middle"
          />
        </b-button>
      </template>
      <v-select
        :id="'attributes.' + index + '.attribute_value_id'"
        v-model="valueData"
        :options="uniqValues"
        :disabled="!uniqValues"
        :reduce="val => val.value"
        :multiple="true"
        :input-id="'attributes.' + index + '.attribute_value_id'"
        @search="searchAttributeValue($event, attribute_id)"
        @input="selectAttributeValue($event, attribute_id)"
      />
    </b-input-group>
    <b-form-invalid-feedback v-if="error">
      {{ error }}
    </b-form-invalid-feedback>
    <value-form
      :opened-value-form.sync="openedValueForm"
      :attribute-id="attribute_id"
      :value-id="0"
      :for-page="false"
      :languages="languages"
      @refetch-data="searchAttributeValue"
    />
  </b-form-group>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import ValueForm from '@/views/catalog/attributes/values/ValueForm'
import _ from 'lodash'

export default {
  components: {
    ValueForm,
  },
  mixins: [GlobalMixin, EditorMixin],
  props: {
    index: {
      type: Number,
      required: true,
    },
    attribute_id: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    languages: {
      type: [Array],
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      openedValueForm: false,
      valuesBase: [],
      values: [],
      originalData: this.value,
    }
  },
  computed: {
    uniqValues() {
      return _.uniqBy(_.concat(this.valuesBase, this.values), 'value')
    },
    valueData: {
      get() {
        return this.originalData
      },
      set(value) {
        this.originalData = value
        this.$emit('input', value)
      },
    },
  },
  watch: {
    value(newValue) {
      this.originalData = newValue
    },
    attribute_id(newValue) {
      this.originalData = []
      this.valuesBase = []
      this.values = []

      if (newValue) {
        this.$http.get(`/api/attributes/${newValue}/values`, { params: { per_page: 20 } })
          .then(response => {
            this.values = _.uniqBy(_.concat(this.valuesBase, this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')), 'value')
          })
      }
    },
  },
  created() {
    if (this.attribute_id && this.value) {
      this.$http.get(`/api/attributes/${this.attribute_id}/values`, { params: { per_page: 20, ids: this.value } })
        .then(response => {
          this.valuesBase = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    }
  },
  methods: {
    async searchAttributeValue(query, aid) {
      if (!query.length) return
      await this.$http.get(`/api/attributes/${aid}/values`, { params: { query, per_page: 20 } })
        .then(response => {
          this.values = _.uniqBy(_.concat(this.valuesBase, this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')), 'value')
        })
    },
    selectAttributeValue(id) {
      this.valuesBase = _.filter(this.uniqValues, uv => _.includes(id, uv.value))
    },
    openEditor() {
      this.openedValueForm = true
    },
  },
}
</script>

<style lang="scss" scoped>
.pav-field {
  flex-wrap: nowrap;

  .prepend,
  .append,
  input {
    height: 38px;
  }

  .v-select {
    width: 100%;
  }
}
</style>
