<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <a
          :href="`${apiUrl}/products/${item.slug}`"
          target="_blank"
          class="btn back-to-list btn-outline-info view-on-front"
        >
          {{ $t('general.viewOnFront') }}
        </a>
        <b-button
          type="button"
          variant="outline-secondary"
          class="back-to-list"
          @click="toList"
        >
          {{ $t('general.back') }}
        </b-button>
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.title.label'"
                        :lang="language.code"
                        :field="`title`"
                        :value="item.title[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.title[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['title.' + language.code] ? errors['title.' + language.code][0] : null"
                        @input="value => item.title[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_h1.label'"
                        :lang="language.code"
                        :field="`meta_h1`"
                        :value="item.meta_h1[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_h1[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_h1.' + language.code] ? errors['meta_h1.' + language.code][0] : null"
                        @input="value => item.meta_h1[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_title.label'"
                        :lang="language.code"
                        :field="`meta_title`"
                        :value="item.meta_title[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_title[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_title.' + language.code] ? errors['meta_title.' + language.code][0] : null"
                        @input="value => item.meta_title[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_description.label'"
                        :lang="language.code"
                        :field="`meta_description`"
                        :value="item.meta_description[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_description[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_description.' + language.code] ? errors['meta_description.' + language.code][0] : null"
                        @input="value => item.meta_description[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_keywords.label'"
                        :lang="language.code"
                        :field="`meta_keywords`"
                        :value="item.meta_keywords[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_keywords[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_keywords.' + language.code] ? errors['meta_keywords.' + language.code][0] : null"
                        @input="value => item.meta_keywords[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.description.label'"
                        :lang="language.code"
                        :field="`description`"
                        :value="item.description[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.description[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['description.' + language.code] ? errors['description.' + language.code][0] : null"
                        @input="value => item.description[language.code] = value"
                      />
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              :title="$t('admin.products.tabs.data.label')"
              @click="loadDataTab"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.currency.label')"
                    label-for="currency_code"
                    :state="errors && errors.currency_code ? false : null"
                  >
                    <v-select
                      id="currency_code"
                      v-model="item.currency_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="currencyOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="currency_code"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.currency_code">
                      {{ errors.currency_code[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="false"
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="categories"
                    :state="errors && errors.categories ? false : null"
                  >
                    <v-select
                      id="categories"
                      v-model="item.categories"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="true"
                      input-id="categories"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.categories">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.top_category.label')"
                    label-for="categories"
                    :state="errors && errors.category_id ? false : null"
                  >
                    <v-select
                      id="category_id"
                      v-model="item.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptionsTop"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="false"
                      input-id="category_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.category_id">
                      {{ errors.category_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.stickers.label')"
                    label-for="stickers"
                    :state="errors && errors.stickers ? false : null"
                  >
                    <v-select
                      id="stickers"
                      v-model="item.stickers"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stickersOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="stickers"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stickers">
                      {{ errors.stickers[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.services.label')"
                    label-for="services"
                    :state="errors && errors.services ? false : null"
                  >
                    <v-select
                      id="services"
                      v-model="item.services"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="servicesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="services"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.services">
                      {{ errors.services[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.sku.label')"
                    label-for="sku"
                  >
                    <b-form-input
                      id="sku"
                      v-model="item.sku"
                      :state="errors && errors.sku ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.sku">
                      {{ errors.sku[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.ean.label')"
                    label-for="ean"
                  >
                    <b-form-input
                      id="ean"
                      v-model="item.ean"
                      :state="errors && errors.ean ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.ean">
                      {{ errors.ean[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                  >
                    <b-form-input
                      id="position"
                      v-model="item.position"
                      type="number"
                      :state="errors && errors.position ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.stock_status.label')"
                    label-for="stock_status_id"
                    :state="errors && errors.stock_status_id ? false : null"
                  >
                    <v-select
                      id="stock_status_id"
                      v-model="item.stock_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statuses"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="stock_status_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stock_status_id">
                      {{ errors.stock_status_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.available_at.label')"
                    label-for="available_at"
                    :state="errors && errors.available_at ? false : null"
                  >
                    <b-form-datepicker
                      id="available_at"
                      v-model="item.available_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.available_at">
                      {{ errors.available_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.novelty_until.label')"
                    label-for="novelty_until"
                    :state="errors && errors.novelty_until ? false : null"
                  >
                    <b-form-datepicker
                      id="novelty_until"
                      v-model="item.novelty_until"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.novelty_until">
                      {{ errors.novelty_until[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="false"
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.min_quantity.label')"
                    label-for="min_quantity"
                  >
                    <b-form-input
                      id="min_quantity"
                      v-model="item.min_quantity"
                      type="number"
                      :state="errors && errors.min_quantity ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.min_quantity">
                      {{ errors.min_quantity[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.images.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <div class="mb-75">
                      <InitFileManager
                        field="avatar"
                        type="product_avatar"
                        :multiple="false"
                        @fm-selected="selectedAvatar"
                      />
                    </div>
                    <!-- media -->
                    <div class="media">
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>
                    </div>
                    <!--/ media -->
                    <div class="mt-75">
                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                >
                  <b-form-group :label="$t('form.gallery.label')">
                    <div class="mb-75">
                      <InitFileManager
                        field="gallery"
                        type="product_gallery"
                        :multiple="true"
                        @fm-selected="selectedGallery"
                      />
                    </div>
                    <!-- media -->
                    <draggable
                      v-model="item.gallery"
                      class="media"
                    >
                      <b-media-aside
                        v-for="(file, index) in item.gallery"
                        :key="index"
                      >
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="file.url"
                            height="80"
                          />
                        </b-link>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.gallery.splice(index, 1)"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                      </b-media-aside>
                    </draggable>
                    <!--/ media -->
                    <div class="mt-75">
                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.video.label')"
                    label-for="video"
                  >
                    <b-form-input
                      id="video"
                      v-model="item.video"
                      :state="errors && errors.code ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.video">
                      {{ errors.video[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.stocks.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(stock, index) in item.stocks"
                  :id="'stock' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.stocks.label')"
                      :label-for="'stocks.' + index + '.stock_id'"
                      :state="errors && errors['stocks.' + index + '.stock_id'] ? false : null"
                    >
                      <v-select
                        :id="'stocks.' + index + '.stock_id'"
                        v-model="stock.stock_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="stocksOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'stocks.' + index + '.stock_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.stock_id']">
                        {{ errors['stocks.' + index + '.stock_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.quantity.label')"
                      :label-for="'stocks.' + index + '.quantity'"
                    >
                      <b-form-input
                        :id="'stocks.' + index + '.quantity'"
                        v-model="stock.quantity"
                        type="number"
                        step="1"
                        min="1"
                        max="999999"
                        :state="errors && errors['stocks.' + index + '.quantity'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.quantity']">
                        {{ errors['stocks.' + index + '.quantity'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeStock(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addStock"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.stocks"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.stocks[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.prices.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(price, index) in item.prices"
                  :id="'price' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('form.role.label')"
                      :label-for="'prices.' + index + '.role_id'"
                      :state="errors && errors['prices.' + index + '.role_id'] ? false : null"
                    >
                      <v-select
                        :id="'prices.' + index + '.role_id'"
                        v-model="price.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rolesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :selectable="priceRolesOptionsSelectable"
                        :input-id="'prices.' + index + '.role_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.role_id']">
                        {{ errors['prices.' + index + '.role_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <MultiPrice
                      v-model="price.price"
                      :index="index"
                      :label="$t('form.price.label')"
                      :type="'prices'"
                      :currency="item.currency_code"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <MultiPrice
                      v-model="price.old_price"
                      :index="index"
                      :label="$t('form.old_price.label')"
                      :type="'prices'"
                      :currency="item.currency_code"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="1"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removePrice(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPrice"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.prices"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.prices[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.discounts.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(discount, index) in item.discounts"
                  :id="'discount' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('form.role.label')"
                      :label-for="'discounts.' + index + '.role_id'"
                      :state="errors && errors['discounts.' + index + '.role_id'] ? false : null"
                    >
                      <v-select
                        :id="'discounts.' + index + '.role_id'"
                        v-model="discount.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rolesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'discounts.' + index + '.role_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.role_id']">
                        {{ errors['discounts.' + index + '.role_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      v-if="item.prices.length"
                      :label="$t('form.percent.label')"
                      :label-for="'discounts.' + index + '.percent'"
                    >
                      <b-form-input
                        :id="'discounts.' + index + '.percent'"
                        v-model="discount.percent"
                        type="text"
                        pattern="\d{1,2}"
                        minlength="1"
                        maxlength="2"
                        inputmode="numeric"
                        @input="setDiscountPercent(index, $event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <MultiPrice
                      v-model="discount.price"
                      :index="index"
                      :label="$t('form.price.label')"
                      :type="'discounts'"
                      :currency="item.currency_code"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.started_at.label')"
                      :label-for="'discounts.' + index + '.started_at'"
                      :state="errors && errors['discounts.' + index + '.started_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.started_at'"
                        v-model="discount.started_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.started_at']">
                        {{ errors['discounts.' + index + '.started_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.ended_at.label')"
                      :label-for="'discounts.' + index + '.ended_at'"
                      :state="errors && errors['discounts.' + index + '.ended_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.ended_at'"
                        v-model="discount.ended_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.ended_at']">
                        {{ errors['discounts.' + index + '.ended_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeDiscount(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addDiscount"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.discounts"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.discounts[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.payments.label')">
              <b-alert
                show
                :style="`info`"
                class="p-1"
              >
                Товар завжди доступний до оплати всіма методами за-замовчуванням. <br>
                Щоб товар був доступний до оплати також унікальними методами - додайте їх нижче
              </b-alert>
              <div class="repeater-form">
                <b-row
                  v-for="(method, index) in item.payment_methods"
                  :id="'method' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.payment_method.label')"
                      :label-for="'payment_methods.' + index + '.payment_method_id'"
                      :state="errors && errors['payment_methods.' + index + '.payment_method_id'] ? false : null"
                    >
                      <v-select
                        :id="'payment_methods.' + index + '.payment_method_id'"
                        v-model="method.payment_method_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="paymentsOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'payment_methods.' + index + '.payment_method_id'"
                        @input="method.payment_method_gateway_id = null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.payment_method_id']">
                        {{ errors['payment_methods.' + index + '.payment_method_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.payment_method_gateway.label')"
                      :label-for="'payment_methods.' + index + '.payment_method_gateway_id'"
                      :state="errors && errors['payment_methods.' + index + '.payment_method_gateway_id'] ? false : null"
                    >
                      <v-select
                        :id="'payment_methods.' + index + '.payment_method_gateway_id'"
                        v-model="method.payment_method_gateway_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="paymentGatewaysOptions[`pm_${method.payment_method_id}`]"
                        :selectable="paymentMethodsGatewaySelectable"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'payment_methods.' + index + '.payment_method_gateway_id'"
                        :disabled="!paymentGatewaysOptions[`pm_${method.payment_method_id}`] || !paymentGatewaysOptions[`pm_${method.payment_method_id}`].length"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.payment_method_gateway_id']">
                        {{ errors['payment_methods.' + index + '.payment_method_gateway_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      v-if="needCredits(method.payment_method_id)"
                      :label="$t('form.parts.label')"
                      :label-for="'payment_methods.' + index + '.parts'"
                    >
                      <b-form-input
                        :id="'payment_methods.' + index + '.parts'"
                        :ref="'payment_methods' + index + 'parts'"
                        v-model="method.parts"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['payment_methods.' + index + '.parts'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.parts']">
                        {{ errors['payment_methods.' + index + '.parts'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removePayment(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPayment"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>
              <b-row
                v-if="errors && errors.payment_methods"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.payment_methods[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.attributes.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(attribute, index) in item.attributes"
                  :id="'attribute' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'attributes.' + index + '.attribute_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_id'"
                        v-model="attribute.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqAttributes"
                        :selectable="attributesSelectable"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_id'"
                        @search="searchAttribute"
                        @input="selectAttribute($event, index)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                        {{ errors['attributes.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <ProductAttributeValue
                      v-model="attribute.attribute_value_id"
                      :index="index"
                      :attribute_id="attribute.attribute_id"
                      :languages="languages"
                      :error="errors && errors['attributes.' + index + '.attribute_value_id'] ? errors['attributes.' + index + '.attribute_value_id'][0] : null"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeAttribute(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>
              <b-row
                v-if="errors && errors.attributes"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.sets.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(set, index) in item.sets"
                  :id="'set' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'sets.' + index + '.product1'"
                    >
                      <b-form-input
                        :id="'sets.' + index + '.product1'"
                        v-model="item.title.uk"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="1"
                  >
                    <b-form-group
                      :label="`.`"
                      :label-for="'sets.' + index + '.plus'"
                    >
                      <b-form-input
                        :id="'sets.' + index + '.plus'"
                        value="+"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'sets.' + index + '.entity_id'"
                      :state="errors && errors['sets.' + index + '.entity_id'] ? false : null"
                    >
                      <v-select
                        :id="'sets.' + index + '.entity_id'"
                        v-model="set.entity_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqProducts"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'sets.' + index + '.entity_id'"
                        :searchable="true"
                        @search="searchProduct"
                        @input="selectProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['sets.' + index + '.entity_id']">
                        {{ errors['sets.' + index + '.entity_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <MultiPrice
                      v-model="set.price"
                      :index="index"
                      :label="$t('form.discount.label')"
                      :type="'sets'"
                      :currency="item.currency_code"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeSet(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addSet"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.sets"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.sets[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.related.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(related, index) in item.relateds"
                  :id="'related' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'related.' + index + '.related_id'"
                      :state="errors && errors['related.' + index + '.related_id'] ? false : null"
                    >
                      <v-select
                        :id="'related.' + index + '.related_id'"
                        v-model="related.related_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqProducts"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'related.' + index + '.related_id'"
                        :searchable="true"
                        @search="searchProduct"
                        @input="selectProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['related.' + index + '.related_id']">
                        {{ errors['related.' + index + '.related_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="10"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'related.' + index + '.attribute_id'"
                      :state="errors && errors['related.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'related.' + index + '.attribute_id'"
                        v-model="related.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqAttributes"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'related.' + index + '.attribute_id'"
                        @search="searchAttribute"
                        @input="selectAttribute($event, null)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['related.' + index + '.attribute_id']">
                        {{ errors['related.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="2"
                    md="1"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeRelated(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addRelated"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.relateds"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.related[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.accessories.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(accessory, index) in item.accessories"
                  :id="'accessory' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'accessory.' + index + '.accessory_id'"
                      :state="errors && errors['accessory.' + index + '.accessory_id'] ? false : null"
                    >
                      <v-select
                        :id="'accessory.' + index + '.accessory_id'"
                        v-model="accessory.accessory_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqProducts"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'accessory.' + index + '.accessory_id'"
                        :searchable="true"
                        @search="searchProduct"
                        @input="selectProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['accessory.' + index + '.accessory_id']">
                        {{ errors['accessory.' + index + '.accessory_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeAccessory(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAccessory"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.accessories"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.accessories[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'products-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import CategoryMixin from '@/mixins/CategoryMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import MultiPrice from '@/components/MultiPrice.vue'
import GptField from '@/components/GptField.vue'
import ProductAttributeValue from '@/components/ProductAttributeValue.vue'

export default {
  components: {
    MultiPrice,
    GptField,
    ProductAttributeValue,
  },
  mixins: [CategoryMixin, GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      oldCategoryId: null,
      apiUrl: process.env.VUE_APP_APP_URL,
      itemModData: {},
      languages: [],
      statuses: [],
      stockStatusOptions: [
        { label: this.$t('general.stock_statuses.available'), value: 'available' },
        { label: this.$t('general.stock_statuses.not_available'), value: 'not_available' },
        { label: this.$t('general.stock_statuses.waiting'), value: 'waiting' },
        { label: this.$t('general.stock_statuses.pre_order'), value: 'pre_order' },
      ],
      typeOptions: [
        { label: this.$t('general.product_types.simple'), value: 'simple' },
        { label: this.$t('general.product_types.variable'), value: 'variable' },
      ],
      documentTypes: [
        { label: this.$t('general.document_types.document'), value: 'document' },
        { label: this.$t('general.document_types.driver'), value: 'driver' },
        { label: this.$t('general.document_types.passport'), value: 'passport' },
      ],
      documentActions: [
        { label: this.$t('general.document_actions.download'), value: 'download' },
        { label: this.$t('general.document_actions.view'), value: 'view' },
      ],
      loading: {
        categories: false,
        stocks: false,
        roles: false,
        attributes: false,
      },
      categories: [],
      categoryOptions: [],
      currencyOptions: [],
      stickersOptions: [],
      servicesOptions: [],
      stocksOptions: [],
      rolesOptions: [],
      paymentsList: [],
      paymentsOptions: [],
      paymentGatewaysOptions: [],
      extensionOptions: [],
      productOptions: [],
      productBaseOptions: [],
      attributesOptions: [],
      attributesBaseOptions: [],
    }
  },
  computed: {
    categoryOptionsTop() {
      return _.filter(this.categoryOptions, cat => _.includes(this.item.categories, cat.value))
    },
    uniqAttributes() {
      return _.uniqBy(_.concat(this.attributesBaseOptions, this.attributesOptions), 'value')
    },
    uniqProducts() {
      return _.uniqBy(_.concat(this.productBaseOptions, this.productOptions), 'value')
    },
  },
  watch: {
    'item.category_id': {
      handler(categoryId) {
        this.$nextTick(() => {
          if (categoryId && this.oldCategoryId !== categoryId) {
            this.addRecommendedAttributes(categoryId)
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })
    await this.$http.get('/api/admin/statuses')
      .then(response => {
        this.statuses = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get('/api/stocks', { params: { status: 'enabled', per_page: 100 } })
      .then(response => {
        this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.$http('/api/currencies')
      .then(response => {
        this.currencyOptions = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })

    await this.$http.get('/api/admin/payment_methods', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.paymentsList = _.filter(response.data.data, p => !p.by_default)
        this.paymentsOptions = this.$options.filters.transformForVSelect(this.paymentsList, 'id', 'title')

        const vm = this

        _.each(this.paymentsList, item => {
          vm.paymentGatewaysOptions[`pm_${item.id}`] = vm.$options.filters.transformForVSelect(item.gateways, 'id', 'title')
        })
      })

    await this.$http.get(`/api/admin/products/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        this.loadAttributes()

        if (this.item.name) {
          this.$route.meta.breadcrumb.pop()
          this.$route.meta.breadcrumb.push({ text: this.item.name, active: true })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'products-index' })
        }
      })
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    selectedGallery(data) {
      this.item.gallery = _.concat(this.item.gallery, data)
    },
    async loadDataTab() {
      if (!Object.keys(this.categoryOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/admin/fullcategories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categories = response.data
            this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
      if (!Object.keys(this.stickersOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/stickers', { params: { status: 'enabled', per_page: 100 } })
          .then(response => {
            this.stickersOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
      if (!Object.keys(this.servicesOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/services', { params: { per_page: 100 } })
          .then(response => {
            this.servicesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title_full')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
    },
    loadAttributes() {
      this.$http.get('/api/attributes', {
        params: {
          sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 20,
        },
      })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    selectProduct(id) {
      if (!_.includes(_.map(this.productBaseOptions, 'value'), id)) {
        this.productBaseOptions.push(this.productOptions.find(a => a.value === id))
      }
    },
    async selectAttribute(id, index) {
      if (!_.includes(_.map(this.attributesBaseOptions, 'value'), id)) {
        this.attributesBaseOptions.push(this.attributesOptions.find(a => a.value === id))
      }

      if (index === undefined) return

      this.item.attributes[index].attribute_value_id = []
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        type: this.item.type,
        video: this.item.video,
        currency_code: this.item.currency_code,
        category_id: this.item.category_id,
        categories: this.item.categories,
        stocks: this.item.stocks,
        stickers: this.item.stickers,
        services: this.item.services,
        prices: this.item.prices,
        discounts: this.item.discounts,
        attributes: this.item.attributes.reduce((acc, item) => {
          const expandedItems = item.attribute_value_id.map(valueId => ({
            attribute_id: item.attribute_id,
            attribute_value_id: valueId,
          }))
          return acc.concat(expandedItems)
        }, []),
        avatar: this.item.avatar,
        gallery: this.item.gallery,
        status: this.item.status,
        indexing_status: this.item.indexing_status,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        sku: this.item.sku,
        ean: this.item.ean,
        min_quantity: this.item.min_quantity,
        position: this.item.position,
        available_at: this.item.available_at,
        novelty_until: this.item.novelty_until,
        stock_status_id: this.item.stock_status_id,
        sets: this.item.sets,
        relateds: this.item.relateds,
        accessories: this.item.accessories,
        payment_methods: this.item.payment_methods,
      }

      this.$http.put(`/api/admin/products/${this.item.id}`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Збережено',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          router.replace({
            name: 'products-update',
            params: { id: this.item.id },
            query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
          })
          this.$store.dispatch('validation/clearErrors')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    addStock() {
      this.item.stocks.push({
        quantity: 1,
        stock_id: null,
      })
    },
    removeStock(index) {
      this.item.stocks.splice(index, 1)
    },
    addPrice() {
      this.item.prices.push({
        price: 1,
        old_price: 0,
        role_id: null,
      })
    },
    removePrice(index) {
      this.item.prices.splice(index, 1)
    },
    addDiscount() {
      this.item.discounts.push({
        price: 1,
        percent: 0,
        started_at: new Date(),
        ended_at: new Date(),
        role_id: 2,
      })
    },
    removeDiscount(index) {
      this.item.discounts.splice(index, 1)
    },
    addPayment() {
      this.item.payment_methods.push({
        id: null,
        payment_method_id: null,
        payment_method_gateway_id: null,
        amount: 0,
        parts: 1,
      })
    },
    removePayment(index) {
      this.item.payment_methods.splice(index, 1)
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: 0,
        attribute_value_id: [],
      })
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
    },
    addSet() {
      this.item.sets.push({
        price: 1,
        entity_id: null,
      })
    },
    removeSet(index) {
      this.item.sets.splice(index, 1)
    },
    addRelated() {
      this.item.relateds.push({
        related_id: null,
        attribute_id: null,
      })
    },
    removeRelated(index) {
      this.item.relateds.splice(index, 1)
    },
    addAccessory() {
      this.item.accessories.push({
        accessory_id: null,
      })
    },
    removeAccessory(index) {
      this.item.accessories.splice(index, 1)
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        type: item.type,
        video: item.video,
        currency_code: item.currency_code,
        category_id: item.category_id,
        avatar: this.$options.filters.singleMedia(item.avatar),
        gallery: this.$options.filters.multipleMedia(item.gallery),
        categories: item.categories,
        stocks: item.stocks,
        prices: [],
        discounts: [],
        payment_methods: [],
        attributes: [],
        stickers: item.stickers,
        services: item.services,
        status: item.status,
        indexing_status: item.indexing_status,
        sku: item.sku,
        ean: item.ean,
        min_quantity: item.min_quantity,
        position: item.position,
        available_at: item.available_at ? item.available_at : new Date(),
        novelty_until: item.novelty_until ? item.novelty_until : new Date(),
        stock_status_id: item.stock_status.id,
        name: item.title,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        sets: [],
        relateds: [],
        accessories: [],
      }
      this.oldCategoryId = item.category_id

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      data.categories = _.map(data.categories, 'id')
      data.stickers = _.map(data.stickers, 'id')
      data.services = _.map(data.services, 'id')

      data.stocks = _.map(data.stocks, stock => ({
        stock_id: stock.id,
        quantity: stock.quantity,
      }))

      data.prices = _.map(item.prices, price => ({
        id: price.id,
        role_id: price.role_id,
        price: price.price,
        old_price: price.old_price,
      }))

      data.payment_methods = _.map(item.paymentMethods, method => ({
        id: method.id,
        payment_method_id: method.payment_method_id,
        payment_method_gateway_id: method.payment_method_gateway_id,
        amount: method.amount,
        parts: method.parts,
      }))

      data.discounts = _.map(item.discounts, discount => ({
        id: discount.id,
        role_id: discount.role_id,
        price: discount.price,
        percent: data.prices[0] ? parseInt(((data.prices[0].price - discount.price) / data.prices[0].price) * 100, 10) : 0,
        started_at: discount.started_at,
        ended_at: discount.ended_at,
      }))

      data.attributes = _.map(
        _.reduce(item.attributes, (acc, i) => {
          if (acc[i.attribute_id]) {
            acc[i.attribute_id].push(i.attribute_value_id)
          } else {
            acc[i.attribute_id] = [i.attribute_value_id]
          }
          return acc
        }, {}),
        (value, key) => ({
          attribute_id: parseInt(key, 10),
          attribute_value_id: value,
        }),
      )

      this.attributesBaseOptions = this.$options.filters.transformForVSelect(_.map(item.attributes, a => a.attribute), 'id', 'title')

      data.sets = _.map(item.sets, set => ({
        id: set.id,
        entity_id: set.entity_id,
        price: set.price,
      }))

      _.each(item.sets, set => {
        this.productBaseOptions.push({
          label: set.entity.title,
          value: set.entity.id,
        })
      })

      data.relateds = _.map(item.relateds, rel => ({
        id: rel.id,
        related_id: rel.related_id,
        attribute_id: rel.attribute_id,
      }))

      _.each(item.relateds, rel => {
        if (rel.attribute) {
          this.attributesBaseOptions.push({
            label: rel.attribute.title,
            value: rel.attribute.id,
          })
        }
        this.productBaseOptions.push({
          label: rel.related.title,
          value: rel.related.id,
        })
      })

      data.accessories = _.map(item.accessories, rel => ({
        id: rel.id,
        accessory_id: rel.accessory_id,
      }))

      _.each(item.accessories, rel => {
        this.productBaseOptions.push({
          label: rel.accessory.title,
          value: rel.accessory.id,
        })
      })

      return data
    },
    setDiscountPercent(index, value) {
      let percent = parseInt(value, 10)
      if (percent < 1) {
        percent = 1
        this.item.discounts[index].percent = percent
      }
      if (percent > 99) {
        percent = 99
        this.item.discounts[index].percent = percent
      }
      const disc = (this.item.prices['0'].price - ((this.item.prices['0'].price * percent) / 100))
      this.item.discounts[index].price = parseInt(disc, 10)
    },
    toList() {
      router.replace({
        name: 'products-index',
        query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
      })
    },
    addRecommendedAttributes(categoryId) {
      const selectedCategory = _.find(this.categories, ['id', categoryId])
      const selectedCategoryAttributes = selectedCategory ? _.reduce(selectedCategory.recommendation_attributes, (result, attribute) => {
        result.push({
          attribute_id: attribute.attribute_id,
          attribute_value_id: [],
        })
        return result
      }, []) : []

      if (!selectedCategoryAttributes.length) return

      this.confirm(() => {
        this.item.attributes = selectedCategoryAttributes
      }, {
        text: 'Додати до товару характеристики, рекомендовані для цієї категорії?',
      })
    },
    needCredits(methodId) {
      return _.some(this.paymentsList, {
        id: methodId,
        type: 'part',
      })
    },
    paymentMethodsGatewaySelectable(option) {
      return !_.includes(_.map(this.item.payment_methods, ipm => ipm.payment_method_gateway_id), option.value)
    },
    attributesSelectable(option) {
      return !_.includes(_.map(this.item.attributes, ipm => ipm.attribute_id), option.value)
    },
    priceRolesOptionsSelectable(option) {
      return !_.includes(_.map(this.item.prices, price => price.role_id), option.value)
    },
    async searchAttribute(query) {
      if (!query.length) return
      await this.$http.get('/api/attributes', { params: { query, per_page: 20 } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchProduct(query) {
      if (!query.length) return
      await this.$http.get('/api/admin/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          if (this.item.sets?.length) {
            _.each(this.item.sets, set => {
              if (set.entity_id) {
                this.$http.get(`/api/admin/products/${set.entity_id}`)
                  .then(res => {
                    this.productOptions.push({ label: res.data.data.title, value: set.entity_id })
                    this.productOptions = _.uniqBy(this.productOptions, 'value')
                  })
              }
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.back-to-list {
  position: absolute;
  top: -75px;
  right: -20px;
}

.view-on-front {
  position: absolute;
  top: -75px;
  right: 80px;
}

.open-modifications {
  position: absolute;
  top: -75px;
  right: 210px;
}
</style>
