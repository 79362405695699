<template>
  <b-form-group
    :label="$t(label)"
    :label-for="`${label}.${lang}`"
  >
    <b-form-input
      v-if="field !== 'description'"
      :id="`${label}.${lang}`"
      v-model="valueData"
      :class="fieldClass"
      :state="error ? false : null"
    />
    <quill-editor
      v-else
      :id="`${label}.${lang}`"
      v-model="valueData"
      :options="editorOptions"
      :state="error ? false : null"
    />
    <b-form-invalid-feedback v-if="error">
      {{ error }}
    </b-form-invalid-feedback>

    <b-input-group class="gpt-field">
      <template #prepend>
        <span class="prepend border-1 border-info">
          GPT
        </span>
      </template>
      <b-form-input
        v-model="question"
        :placeholder="placeholder"
        class="border-info"
      />
      <template #append>
        <b-button
          variant="info"
          class="append"
          @click="generateByGpt"
        >
          <feather-icon
            icon="PlayCircleIcon"
            size="16"
            class="align-middle"
          />
        </b-button>
        <b-button
          variant="secondary"
          class="append"
          @click="translateByGpt"
        >
          <b-img
            :src="require('@/assets/images/flags/' + fromLang + '.svg')"
            height="16"
            width="16"
          />
          >
          <b-img
            :src="require('@/assets/images/flags/' + lang + '.svg')"
            height="16"
            width="16"
          />
        </b-button>
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  mixins: [GlobalMixin, EditorMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      required: true,
      default: null,
    },
    field: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: '',
    },
    translate: {
      type: String,
      required: '',
    },
    lang: {
      type: String,
      default: 'uk',
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      question: null,
      fieldClass: '',
      originalData: this.value,
    }
  },
  computed: {
    fieldTitle() {
      if (this.field === 'title') {
        return 'Коректну назву'
      }
      if (this.field === 'description') {
        return 'Опис довжиною 2-3 тисячі символів в форматі html (допустимі теги: h3,h4,p,ul,li,b,br). Генерувати список характеристик товару не потрібно.'
      }
      if (this.field === 'meta_h1') {
        return 'Текст для тегу Н1'
      }
      if (this.field === 'meta_title') {
        return 'Текст в Мета-тег Title'
      }
      if (this.field === 'meta_description') {
        return 'Текст в Мета-тег Description'
      }
      if (this.field === 'meta_keywords') {
        return '5 ключових слів через кому'
      }
      return this.$t(`form.${this.field}.label`)
    },
    placeholder() {
      return `Згенеруй ${this.fieldTitle} для товару "${this.title}"`
    },
    prompt() {
      return this.question ? this.question : this.placeholder
    },
    fromLang() {
      return this.lang === 'uk' ? 'ru' : 'uk'
    },
    valueData: {
      get() {
        return this.originalData
      },
      set(value) {
        this.originalData = value
        this.$emit('input', value)
      },
    },
  },
  watch: {
    value(newValue) {
      this.originalData = newValue
    },
  },
  methods: {
    async generateByGpt() {
      const self = this
      this.$http.post('/api/admin/gpt/generate/', { prompt: this.prompt, lang: this.lang })
        .then(response => {
          this.valueData = response.data.data
          this.fieldClass = 'border-success'
          setTimeout(() => {
            self.fieldClass = ''
          }, 2000)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.response.data.message)
          this.fieldClass = 'border-error'
          setTimeout(() => {
            self.fieldClass = ''
          }, 2000)
        })
    },
    async translateByGpt() {
      const self = this
      this.$http.post('/api/admin/gpt/translate/', { text: this.translate, from: this.fromLang, to: this.lang })
        .then(response => {
          this.valueData = response.data.data
          this.fieldClass = 'border-success'
          setTimeout(() => {
            self.fieldClass = ''
          }, 2000)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.fieldClass = 'border-error'
          setTimeout(() => {
            self.fieldClass = ''
          }, 2000)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.gpt-field {
  .prepend,
  .append,
  input {
    height: 40px;
  }

  .prepend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 4px 0 0 4px;
  }
}
</style>
